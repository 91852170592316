@keyframes stopAt0 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes stopAt45 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}

@keyframes stopAt90 {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes stopAt135 {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(135deg);
  }
}

@keyframes stopAt180 {
  0% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.animation-container {
  position: relative;
  width: 200px;
  height: 120px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-wrapper {
  position: absolute;
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gradient-circle {
  position: absolute;
  width: 100px;
  height: 100px;
  background: radial-gradient(circle, #fdb201 0%, transparent 70%);
  border-radius: 50%;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
