@keyframes buttonIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button-in {
  animation: buttonIn 1s ease-in forwards;
}

@keyframes endingIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.endings {
  animation: endingIn 1s ease-in forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up {
  animation: slideUp 1s ease-out forwards;
}
